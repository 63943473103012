import './style.css';
import ImgMarketingCliente from '../../images/marketin_cliente.png';
import ImgMarketingProgresso from '../../images/marketing_progresso.png';
import ImgMarketingLista from '../../images/marketing_lista.png';
import ImgMarketingInstancia from '../../images/marketing_instancia.png';
import ImgIaPrincipal from '../../images/ia_principal.png';
import ImgExploracao from '../../images/exploracao.png';
import ImgInterativo from '../../images/interativo.png';

const Produtos = () => 
    {
        return <section id='produto'>
            <div className='produto'>
                <h1>PRODUTO</h1> 
                <h2>Experimente o MagChatter e aproveite a inteligência artificial de ponta para 
                    transformar e gerenciar seus dados com eficiência.</h2>
            </div>
            <div className='quadro-one'>
            
                <div className='produto-quadro'>
                <img src={ImgIaPrincipal}/>
                    <div className='produto-conteudo'>
                        <h1>Magcros AI</h1>
                        <p>Magcros AI, alimentado pela poderosa tecnologia GPT-4 Turbo, oferece a mesma inteligência e desempenho de uma IA 
                            de ponta, mas com uma proposta de valor altamente competitiva. Ele é projetado para empresas que buscam 
                            otimizar suas interações com uma solução eficiente e acessível. Com o Magcros AI, você tem acesso às capacidades 
                            avançadas da IA, ideal para suportar suas operações, sem a complexidade ou custo elevado de outras 
                            alternativas do mercado.
                        </p>
                    
                    </div>
                    
                </div>
                <div className='produto-quadro-one-produto'> 
                
                    <div className='produto-conteudo-one'>
                        <h1>MagChatter</h1>
                        <p>"MagChatter” potenciado pelas mais avançadas tecnologias de IA, como GPT-4 Turbo. 
                            O MagChatter otimiza a gestão de dados e se integra perfeitamente aos seus sistemas 
                            de WhatsApp, Facebook e Instagram, elevando a eficiência e a organização das suas 
                            interações. Além disso, ajuda a impulsionar suas vendas ao identificar oportunidades
                            de recuperação e reengajamento de clientes, maximizando o retorno sobre o 
                            investimento.
                        </p>
                        
                    </div>
                    <img src={ImgMarketingCliente} alt="" />
                </div>
            </div>
            <div className='produto-quadro-passo-a-passo'>       
                <div className='produto-conteudo-passo-a-passo'>
                    <h1>Veja como nossas funcionalidades principais podem facilitar e otimizar seu trabalho 
                        diário. Nossas soluções são projetadas para serem intuitivas e eficazes.</h1>
                </div>
                <div className='produto-img'>
                    <div className='produto-img-passo-a-passo'>
                        <img src={ImgIaPrincipal} />
                        <div className='produto-texto-passo-a-passo'>
                            <h3>Descubra as soluções que você pode criar com IA!</h3>
                            <p>Transforme suas ideias em realidade com nossa plataforma intuitiva e fácil de usar. Digite o que 
                                deseja criar e deixe a IA fazer o trabalho pesado. Escolha entre:</p>
                            <p><strong>Chat com IA:</strong> Obtenha respostas rápidas e precisas.</p>
                               <p><strong>Exploração Interativa:</strong> Navegue e interaja com insights personalizados.</p>
                             <p><strong>Resumo de Arquivos:</strong> Resuma grandes volumes de texto de maneira eficiente.</p>
                            <p>Digite sua ideia e clique em "Criar" para começar!</p>
                        </div>
                    </div>
                    <div className='produto-img-passo-a-passo'>
                    
                        <div className='produto-texto-passo-a-passo'>
                            <h3>Exploração Interativa: Descubra Soluções Inteligentes</h3>
                            <p>A IA responde suas perguntas e oferece sugestões personalizadas para seus interesses. 
                                Explore temas como:</p>
                            <p>Comunicação eficaz em equipe</p>
                              <p>Organização de rotina familiar</p>
                                <p>Superação de desafios no aprendizado</p>
                                <p>Início de um blog ou canal sobre futebol</p>
                                <p>Planejamento de eventos</p>
                            
                            <p>Receba insights e estratégias para alcançar seus objetivos de forma prática e eficiente.</p>
                        </div>
                        <img src={ImgExploracao} />
                    </div>
                    <div className='produto-img-passo-a-passo'>
                        <img src={ImgInterativo} />
                        <div className='produto-texto-passo-a-passo'>
                            <h3>Resumo Interativo: Simplifique Seu Conhecimento</h3>
                            <p>Carregue vídeos, músicas ou textos e obtenha resumos automáticos com respostas instantâneas. 
                                Personalize conforme sua necessidade:</p>
                               <p><strong>Fonte de conhecimento:</strong> Anexe links, vídeos, PDFs ou outros documentos para 
                               adicionar detalhes e especificidade.</p>
                                <p><strong>Comprimento do resumo:</strong> Escolha entre curto, médio ou longo, conforme a 
                                profundidade desejada.</p>
                                <p><strong>Informações adicionais:</strong> Inclua contexto extra, público-alvo ou detalhes técnicos
                                para refinar ainda mais o conteúdo gerado pela IA.</p>
                            <p>Torne o processo de assimilação de informações mais rápido e eficiente!</p>
                        </div>
                    </div>
                    <div className='produto-img-passo-a-passo'>
                        
                        <div className='produto-texto-passo-a-passo'>
                            <h3>Plataforma de Marketing Inteligente: Conecte-se com Seus Clientes</h3>
                            <p>Gerencie sua lista de clientes e otimize suas campanhas diretamente na plataforma. 
                                Envie mensagens personalizadas e acompanhe as respostas em tempo real.</p>
                               <p><strong>Envio de Mensagens:</strong>Envie comunicações diretamente aos seus clientes e 
                               mantenha contato de maneira fácil e eficaz.</p>
                                <p><strong>Monitoramento de Respostas:</strong> Veja as respostas dos seus clientes 
                                em tempo real e acompanhe o desempenho das suas campanhas.</p>
                                <p><strong>Atualização Automática:</strong> Mantenha seus dados de clientes 
                                atualizados com um clique para garantir precisão nas interações.</p>
                            <p>Simplifique suas estratégias de marketing e melhore o relacionamento com seus 
                                clientes através de uma plataforma intuitiva e poderosa!</p>
                        </div>
                        <img src={ImgMarketingCliente} />
                    </div>
                    <div className='produto-img-passo-a-passo'>
                        <img src={ImgMarketingProgresso} />
                        <div className='produto-texto-passo-a-passo'>
                            <h3>Gerencie Suas Campanhas com Facilidade</h3>
                            <p>Acompanhe o progresso de suas campanhas de marketing em 
                                tempo real e otimize suas estratégias com eficiência.</p>
                               <p><strong>Progresso da Campanha:</strong> Monitore o andamento das suas campanhas 
                               com indicadores visuais de progresso, garantindo que todas as etapas sejam concluídas 
                               conforme o planejado.</p>
                                <p><strong>Canais de Envio:</strong>Veja qual canal está sendo utilizado, 
                                como WhatsApp Móvel, para garantir que as mensagens cheguem ao seu público-alvo 
                                de forma eficaz.</p>
                                <p><strong>Status de Envio:</strong>Verifique o status atual da campanha — 
                                como "Enviando mensagens" — e mantenha-se atualizado sobre o andamento.</p>
                                <p><strong>Datas e Prazo:</strong>Controle quando as campanhas começaram e quando 
                                estão programadas para terminar, garantindo um planejamento preciso.</p>
                            <p>Simplifique a gestão de campanhas e maximize seus resultados com uma visão clara e intuitiva do progresso!</p>
                        </div>
                        
                    </div>
                    <div className='produto-img-passo-a-passo'>
                        
                        <div className='produto-texto-passo-a-passo'>
                            <h3>Gerencie Suas Listas de Contatos de Forma Eficiente</h3>
                            <p>Organize e gerencie suas listas de contatos com facilidade, mantendo suas 
                                campanhas de marketing sempre alinhadas e focadas no público certo.</p>

                               <p><strong>Criação de Listas:</strong>Crie listas personalizadas de contatos com 
                               base em suas necessidades de segmentação.</p>

                                <p><strong>Descrição Detalhada:</strong>Adicione descrições para facilitar 
                                a identificação e o propósito de cada lista.</p>

                                <p><strong>Número de Contatos:</strong>Visualize a quantidade de contatos por 
                                lista para otimizar o alcance das suas campanhas.</p>

                                <p><strong>Atualizações em Tempo Real:</strong>Mantenha suas listas sempre 
                                atualizadas e prontas para suas próximas campanhas.</p>

                            <p>Com a gestão de listas simplificada, suas campanhas serão mais direcionadas e eficazes!</p>
                        </div>
                        <img src={ImgMarketingLista} />
                    </div>
                    <div className='produto-img-passo-a-passo'>
                    <img src={ImgMarketingInstancia} />
                        <div className='produto-texto-passo-a-passo'>
                            <h3>Gerencie Instâncias de Marketing com Eficiência</h3>
                            <p>A interface da Magcros permite que você controle suas instâncias de campanhas com facilidade,
                                 garantindo que cada ação seja monitorada e ajustada em tempo real. Veja como você pode 
                                 melhorar suas operações de marketing:</p>

                               <p><strong>Descrição das Instâncias:</strong>Identifique rapidamente suas campanhas 
                               através de descrições claras e canais utilizados, como "WhatsApp Móvel", para 
                               otimizar o envio de mensagens aos seus clientes.</p>

                                <p><strong>Status em Tempo Real:</strong>Monitore o status das suas instâncias de 
                                campanha diretamente na plataforma. Saiba se está Ativo ou finalizado, e garanta 
                                que todas as configurações estejam completas antes do envio.</p>

                                <p><strong>Atualizações Visuais:</strong>Veja quando sua campanha foi criada e 
                                atualizada pela última vez, mantendo controle total sobre os prazos e progresso.</p>

                                <p><strong>Configuração Finalizada:</strong>Acompanhe a configuração de cada 
                                instância com indicadores visuais, garantindo que tudo esteja pronto para o 
                                lançamento da campanha.</p>

                            <p>Com a Magcros, você tem uma visão centralizada e organizada de todas as suas 
                                campanhas, permitindo gerenciar múltiplas instâncias de maneira eficaz. Atualize, 
                                monitore e ajuste suas campanhas em apenas alguns cliques para garantir a máxima 
                                performance!</p>
                        </div>
                        
                    </div>
                    
                </div>  
            </div>
            
        </section>
    }

export default Produtos;