import './style.css'
import Img from "../../images/apresentacao.png"

const Apresentacao = () => {
    
        return  <section className="Features" id='apresentacao'>
          <div className='frase'>
            <h1>Somos a solução ideal para suas necessidades</h1>
            <h3>Proporcionamos automações mais eficientes, otimização de processos, insights em 
            tempo real e decisões mais precisas com nossas IAs.</h3>
          </div>
          <img src={Img} alt="" />
          {/* <img src="https://firebasestorage.googleapis.com/v0/b/magcros-814ef.appspot.com/o/img_fundo.webp?alt=media&token=3bccc840-ba70-43fc-8000-73f3594ff3eb" alt="" /> */}
          <div className='produto-quadro-one'>
                <h1>Benefícios dos produtos</h1> 
                <div className='produto-quadro-beneficios'>
                    <div className='produto-conteudo-one'>
                        <h1>MagChatter</h1>
                        <p>O MagChatter revoluciona a comunicação em plataformas como WhatsApp, Facebook e Instagram, oferecendo uma 
                            gestão de interações mais eficiente e organizada. Utilizando tecnologias avançadas de IA, o sistema coleta 
                            e organiza todas as mensagens e dados, fornecendo informações detalhadas que ajudam a otimizar suas 
                            estratégias de comunicação.
                        </p>
                        <p>Embora a IA não faça a análise direta, ela facilita a coleta e a estruturação das informações, permitindo que 
                            você obtenha insights valiosos e tome decisões mais informadas. Com funcionalidades exclusivas como pastas e 
                            fluxos personalizados.
                        </p>
                    </div>
                    <div className='produto-conteudo-one'>
                        <h1>Magcros AI</h1>
                        <p>O Magcros AI foi construído com base na API do ChatGPT, aproveitando todo o poder e a versatilidade dessa 
                            tecnologia avançada para oferecer respostas naturais, criativas e precisas. Ele utiliza os mesmos dados e 
                            funcionalidades que o ChatGPT, tornando-o uma solução eficaz para criação de conteúdo, respostas automáticas, 
                            atendimento ao cliente e muito mais.
                        </p>
                        <p>Embora o Magcros AI não realize análises diretas, ele organiza e estrutura informações de maneira eficiente, 
                            facilitando a obtenção de insights valiosos. Com sua flexibilidade e personalização, o Magcros AI se adapta às 
                            suas necessidades, oferecendo a mesma qualidade do ChatGPT para otimizar sua produtividade e tomada de 
                            decisões.
                        </p>
                    </div>
                </div>
            </div>
      </section>
    
}
export default Apresentacao;