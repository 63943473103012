import Accordion from './components';
import './style.css'

const Faq = () => {
    
        return <section id='faq'>
            <div className='body-faq'>
                <div className='faq'>
                    <p>FAQ</p>
                    <h1>Restou alguma dúvida?</h1>
                </div>
                <div className='body-perguntas'>
                    <div className='perguntas'>
                            <Accordion pergunta={'O que é a Magcros?'} resposta={'A Magcros é uma empresa de tecnologia inovadora especializada em soluções de inteligência artificial. Desenvolvemos produtos projetados para transformar a gestão de dados e fornecer suporte especializado para diversos setores, otimizando processos e melhorando a eficiência.'}/>
                            <Accordion pergunta={'Quais são os principais produtos da Magcros?'} resposta={'MagChatter: Um sistema de envio automatizado de mensagens e gestão de interações multicanal. Magcros AI: Uma IA avançada construída com base na API do ChatGPT, que oferece suporte inteligente para comunicação e criação de conteúdo.'}/>
                            <Accordion pergunta={'Como o Magcros AI pode beneficiar minha empresa?'} resposta={'O Magcros AI oferece uma experiência de IA sofisticada, proporcionando respostas rápidas, precisas e naturais, semelhantes ao ChatGPT. Ele pode ser utilizado em atendimento ao cliente, automação de tarefas e suporte na criação de conteúdo, otimizando suas operações e melhorando a produtividade.'}/>
                            <Accordion pergunta={'Como o MagChatter pode beneficiar minha empresa?'} resposta={'O MagChatter fornece dados detalhados sobre interações e clientes, permitindo que você identifique e recupere clientes perdidos de forma eficaz. Ao oferecer insights precisos, o sistema ajuda a reduzir a perda de vendas e facilita o reengajamento de clientes, maximizando o retorno sobre o investimento (ROI).'}/>
                            <Accordion pergunta={'O que o MagChatter oferece?'} resposta={'Envio automatizado de mensagens, segmentação de audiência, análise de dados e insights, personalização de mensagens, integração multicanal, relatórios e métricas com IA, respostas pré-configuradas.'}/>
                        </div>
                        <div className='perguntas'>
                            <Accordion pergunta={'Posso testar os produtos da Magcros antes de comprar?'} resposta={'Sim, você pode testar todos os nossos produtos por 30 dias de forma gratuita. Aproveite esse período para explorar nossas soluções e ver como elas podem beneficiar sua empresa. Após o período de teste, o pagamento será efetuado automaticamente. Caso não esteja satisfeito, você poderá solicitar o reembolso dentro de 30 dias após o pagamento.'}/>
                            <Accordion pergunta={'E se eu não estiver satisfeito com os produtos?'} resposta={'Garantimos sua satisfação. Se você não estiver satisfeito, poderá solicitar um reembolso integral dentro dos primeiros 30 dias após a compra. Basta entrar em contato com nosso suporte.'}/>
                            <Accordion pergunta={'Os produtos da Magcros são seguros?'} resposta={'Sim, a segurança dos seus dados é uma prioridade para nós. Utilizamos tecnologias avançadas e as melhores práticas de segurança para proteger suas informações.'}/>
                            <Accordion pergunta={'A Magcros oferece suporte ao cliente?'} resposta={'Sim, nossa equipe de suporte está disponível para ajudar com qualquer dúvida ou problema, garantindo que você tire o máximo proveito de nossas soluções.'}/>
                            
                        </div>
                </div>
            </div>
        </section>
    
}
export default Faq;