import React from 'react';
import './index.css';
import Rodape from './components/rodape';
import Header from './components/cabecalho';
import Apresentacao from './pages/apresentacao';
import Garantia from './pages/garantia';
import Produtos from './pages/produto';
import Sobre from './pages/sobre';
import Faq from './pages/faq';
import { Route, HashRouter as Router, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
     <header>
     <Header/>
     </header>
      <main>
       <Apresentacao/>
       <Sobre/>
       <Produtos/>
       <Garantia/>
       
       <Faq/>
       
      </main>
      <Rodape/>
    </div>
  );
}
// const MainContent = () => {
//   return (
//       <>
//           <Router>
//             <Routes>
//                 <Route path="/" element={<Apresentacao />} />
//                 <Route path="/#sobre" element={<Sobre />} />
//                 <Route path="/#produto" element={<Produtos />} />
//                 <Route path="/#garantia" element={<Garantia />} />
//                 <Route path="/#faq" element={<Faq />} />
//             </Routes>
//           </Router>
//       </>
//   );
// };


export default App;
