import './style.css';

const Sobre = () => 
    {
        return <section id='sobre'>
            
            <div className='body-sobre'>
                <h1>NÓS</h1>
                    <div className='images'>
                       <div>
                            <img src="https://firebasestorage.googleapis.com/v0/b/magcros-814ef.appspot.com/o/joao.jpg?alt=media&token=c700f5cd-3c79-4acb-8b55-87ae0e729c00" alt="" />
                            <p>João Vitor - CEO</p>
                       </div>
                       <div>
                            <img src="https://firebasestorage.googleapis.com/v0/b/magcros-814ef.appspot.com/o/pedro.jpg?alt=media&token=493e7043-f003-44d4-831b-8edc63c0e77d" alt="" />
                            <p>Pedro - CEO</p>
                       </div>
                    </div>
                <h3>Na Magcros, acreditamos que a inovação e a tecnologia são a chave para o futuro.</h3>
            </div>
            <div className='quadro-sobre-polvo'>
               <div className='img-sobre'>
               <h1>Fle-xi-bi-li-da-de</h1>
               <h2>MAGCROS</h2>
                <img src="https://firebasestorage.googleapis.com/v0/b/magcros-814ef.appspot.com/o/polvo.png?alt=media&token=49952d1d-1ef2-4863-a9cb-3a09fa7f6ab3" alt="" id='polvo' />
                <p>"A flexibilidade para se adaptar rapidamente e adotar as melhores estratégias é o 
                    caminho para o sucesso em qualquer contexto."</p>
               </div>
            </div>
           
        </section>
    }

export default Sobre;