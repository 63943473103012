import React from 'react';
import './style.css'
import { FaFacebook, FaInstagram, FaTiktok, FaWhatsapp } from 'react-icons/fa';
import { MdEmail} from 'react-icons/md';
import { BrowserRouter as Router, Link, Routes, Route} from 'react-router-dom';

// const scrollToId = (id: string) => {
//      const element = document.getElementById(id);
//      if (element) {
//          element.scrollIntoView({ behavior: 'smooth' });
//      }
//  };

function Rodape(){
    return <footer>
       <div className='body'>
       <section className='mapa'>
            <h2>Mapa</h2>
            <div className='links'>
               <Router>
                    <Link to="#apresentacao" >Apresentação</Link>
                    <Link to="#sobre">Sobre</Link>
                    <Link to="#produto">Produtos</Link>
                    <Link to="#garantia">Garantia</Link>
                    <Link to="#faq">Faq</Link>
               </Router>
                
            </div>
       </section>
       <section className='redes-sociais'>
            <h2>Redes Sociais</h2>
            <div className='links'>
            <a href="https://www.facebook.com/profile.php?id=61563760771596"> <FaFacebook/> Facebook</a>
            <a href="https://www.instagram.com/magcros01/"><FaInstagram/> Instagram</a>
            <a href="https://www.tiktok.com/@magcros01?lang=pt-BR"><FaTiktok/> TikTok</a>
            </div>
       </section>
       <section className='contato'>
            <h2>Fale Conosco</h2>
           <div className='links'>
           <a href="http://email.com"><MdEmail/> duvidas@magcros.com</a>
           <a href="https://wa.me/${5517996700461}?text=<mensagem_opcional>" ><FaWhatsapp/> +55 17 99670-0461</a>
           </div>
            <p>Atendimento humanizado, todos os dias da semana, das 09h00 às 17h00.</p>
       </section>
       <section className='info-empresa'>
            <img src="https://firebasestorage.googleapis.com/v0/b/magcros-814ef.appspot.com/o/logo.svg?alt=media&token=52bd192d-9778-4496-8b25-ab73b3bf8fb7" alt="" />
            <p>CNPJ: 57.438.623/0001-68</p>
            <p>Rua Bahamas, 345, Apt 301</p>
            <p>Tímbo, Das Nações SC - CEP: 89120-000</p>
       </section>
       </div>
       <p>&copy; 2024 Magcros</p>
    </footer>
}

export default Rodape;