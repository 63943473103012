import { useState } from 'react';
import './style.css';
import { Link } from 'react-router-dom';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="App-header">
      <div>
        <img src="https://firebasestorage.googleapis.com/v0/b/magcros-814ef.appspot.com/o/logo.svg?alt=media&token=52bd192d-9778-4496-8b25-ab73b3bf8fb7" alt="logo da empresa magcros, letras brancas e icone na cor azul, tipo um espiral" />
      </div>

      <button className="menu-button" onClick={toggleMenu}>
        ☰
      </button>
      <div className={`links-menu-div ${isMenuOpen ? 'open' : ''}`}>
        <a className="links-menu" href="https://google.com">
          O que é a Magcros?
        </a>
        <a className="links-menu" href="https://google.com">
          Soluções
        </a>
        <a className="links-menu" href="https://google.com">
          Para Empresas
        </a>
        <a href="https://app.magcros.com/#/login" target="_blank"><button type="button">Login</button></a>
      </div>
    </div>
  );
}

export default Header;
